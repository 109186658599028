<template>
    <div class="fc-map__page-body">
        <div class="body-1 my-3">
            <table class="fc-map__table table_meteo_data">
                <thead>
                    <tr class="fc-map__table-row">
                        <th  v-for="(item, index) in items" :key="index">
                            {{ $t('meteoTable.'+index.replace(/\s/g, '')) }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in data['dates']" :key="index">
                    <td>
                        {{ item }}
                    </td>
                    <td nowrap>
                        {{ data['Precipitation'][index] }} {{$t('charts.units.mm') }}
                    </td>
                </tr>
            </tbody>
            </table>

        </div>
    </div>
</template>

<style>
.table_meteo_data {
    font-size: 10px;
    text-align: center;
}
.table_meteo_data thead > tr:nth-of-type(odd) {
   background-color: rgba(0, 0, 0, .09);
 }

 .table_meteo_data tbody > tr:nth-of-type(odd) {
   background-color: rgba(0, 0, 0, .05);
 }

 .table_meteo_data table td {
    width: 33%;
    padding: 0;
 }
</style>
<script>
import generalFormMixin from '~/components/mixins/generalFormMixin'

export default {
    mixins: [generalFormMixin],
    props: {
        data: {
            type: Object,
            default: null
        }
    },
    computed: {
        showChart () {
            return this.data && !_.isEmpty(this.data) && this.provider
        },
        items() {
            var items = []
            Object.keys(this.data).forEach((field) => {
                if (field != 'actual' && field != 'prov' && field != 'Soil' && field != 'Soil temperature' && field != 'Air Temperature' && field != 'Relative Humidity' && field != 'Watermarks' && field != 'Temperature' && field != 'VIC' && field != 'Wind direction' && field != 'Wind speed' && field != 'SAT') {
                    items[field] = this.data[field]
                }
            })
            return Object.assign({}, items)
        }
    }
}
</script>